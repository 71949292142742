import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'src/context/authContext';
import { doSignOut } from 'src/firebase/auth';
import {
  LogoutOutlined,
  StarOutlined,
  UserOutlined,
  FormOutlined,
} from '@ant-design/icons';

import './navMenu.scss';

import LogoGonher from '../../img/gonher_main.png';

export function NavMenu() {
  const userLocal = JSON.parse(localStorage.getItem('user'));

  const navigate = useNavigate();
  const [current, setCurrent] = useState('');
  const { currentUser, userData, setUserLoggedIn } = useAuth();

  const onClick = (e) => {
    setCurrent(e.key);
  };

  useEffect(() => {
    if (current !== window.location.pathname) {
      setCurrent(window.location.pathname);
    }
  }, [window.location.pathname]);

  // console.log('user menu: ', user.role);
  // console.log('currentUser menu: ', currentUser);
  return (
    <nav
      className='navbar navbar-expand-lg navbar-dark'
      style={{
        paddingBlock: '1rem',
        backgroundColor: 'black',
        color: 'white',
        paddingInline: '9.5%',
      }}
    >
      <a
        className='navbar-brand'
        href='https://www.ganacongonher.com/'
        target='_blank'
        style={{ display: 'inline' }}
      >
        <img src={LogoGonher} style={{ width: '275px' }} />
      </a>
      <button
        className='navbar-toggler'
        type='button'
        data-toggle='collapse'
        data-target='#navbarNav'
        aria-controls='navbarNav'
        aria-expanded='true'
        aria-label='Toggle navigation'
      >
        <span className='navbar-toggler-icon'></span>
      </button>
      <div
        className='collapse navbar-collapse'
        id='navbarNav'
        style={{ justifyContent: 'flex-end', marginRight: '2rem' }}
      >
        <ul className='navbar-nav'>
          <li className='nav-item'>
            {currentUser?.email ? (
              <div key='email' style={{ marginTop: '8px' }}>
                {userLocal.role === 'user' ? userLocal.nickname : 'Admin'}
              </div>
            ) : null}
          </li>
          <li className='nav-item'>
            <a
              className='nav-link'
              href='#'
              onClick={() => {
                userLocal.role === 'user'
                  ? navigate('/') //Chage redirection to root
                  : navigate('/admin');
              }}
            >
              <UserOutlined /> Inicio
            </a>
          </li>
          <li className='nav-item'>
            <a
              className='nav-link'
              href='#'
              onClick={() => {
                navigate('/top-10');
              }}
            >
              <StarOutlined /> Top10
            </a>
          </li>
          <li className='nav-item'>
            <a
              className='nav-link'
              href='#'
              onClick={() => {
                doSignOut().then(() => {
                  localStorage.setItem('user', JSON.stringify({}));
                  navigate('/');
                });
              }}
            >
              <LogoutOutlined /> CerrarSesión
            </a>
          </li>
        </ul>
      </div>
    </nav>
  );
}

// export default NavMenu;
