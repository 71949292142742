import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  Input,
  Button,
  Typography,
  Alert,
  Row,
  Col,
  ConfigProvider,
  theme,
} from 'antd';
import {
  UserOutlined,
  LockOutlined,
  EyeInvisibleFilled,
  EyeOutlined,
} from '@ant-design/icons';
import { Toaster, toast } from 'sonner';
import './Login.scss';
import { useAuth } from 'src/context/authContext';
import {
  doPasswordReset,
  doSignInWithEmailAndPassword,
} from 'src/firebase/auth';
import { Link, Navigate } from 'react-router-dom';
import GanaConGonherBanner from '../../img/gonher_main.png';
import GanaConGonherArt from '../../img/GONHER-F1--familia-productos.png';
import { doSignOut } from 'src/firebase/auth';
import { useNavigate } from 'react-router-dom';

export const Login = () => {
  const navigate = useNavigate();
  const userLocal = JSON.parse(localStorage.getItem('user'));

  const { Title, Text } = Typography;
  const { userLoggedIn, userData } = useAuth();
  const [showPass, setShowPass] = useState(false);
  const [isSignIn, setIsSignIn] = useState(false);
  const [errorLoggedIn, setErrorLoggedIn] = useState(false);
  const [recoveryPass, setRecoveryPass] = useState(false);
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async ({ email, password }) => {
    if (!isSignIn) {
      setIsSignIn(true);
      await doSignInWithEmailAndPassword(email, password)
        .then(() => {
          // console.log('error here i...', items)
          //     setTimeout(() => {
          //       if(items) {
          //       } else {
          //         navigate('/');
          //       }
          // }, 5000);
        })
        .catch((err) => {
          // console.log('error here ...', err)
          setIsSignIn(false);
          setErrorLoggedIn(true);
        });
    }
  };

  const handleChangePassword = async ({ email }) => {
    if (!isSignIn) {
      await doPasswordReset(email);
      setRecoveryPass(false);
    }
    toast.success('Revise su correo para reestablecer contraseña');
  };

  // useEffect(() => {
  //   localStorage.setItem('user', JSON.stringify({}));
  //   doSignOut()
  // }, []);

  return (
    <ConfigProvider
      theme={{
        algorithm: theme.darkAlgorithm,
      }}
    >
      <Row className='back'>
        <Col span={24} md={{ span: 12 }} className='banner-container'>
          <div style={{ display: 'block', textAlign: 'center' }}>
            <img
              src={GanaConGonherBanner}
              alt=''
              style={{
                width: '80%',
              }}
            />
            <img
              className='gana-con-conher-art'
              src={GanaConGonherArt}
              alt=''
              style={{
                width: '100%',
                marginLeft: '2rem',
                marginBottom: '2rem',
              }}
            />
          </div>
        </Col>
        <Col span={24} md={{ span: 12 }} className='login'>
          {userLoggedIn & (userLocal?.role === 'user') ? (
            <Navigate to={'/mis-folios'} replace={true} />
          ) : userLoggedIn & (userLocal?.role === 'admin') ? (
            <Navigate to={'/admin'} replace={true} />
          ) : (
            ''
          )}
          <div className='login-container'>
            <div className='form-container'>
              {!recoveryPass ? (
                <Title level={2}>Iniciar Sesión</Title>
              ) : (
                <Title level={2}>Recuperar contraseña</Title>
              )}
              <form>
                <Controller
                  name='email'
                  control={control}
                  rules={{
                    required: 'Este campo es obligatorio.',
                    pattern: {
                      value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                      message: 'Invalid email address',
                    },
                  }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      className='login-email'
                      type='email'
                      placeholder='ejemplo@correo.com'
                      prefix={<UserOutlined />}
                    />
                  )}
                />
                {!recoveryPass ? (
                  <Controller
                    name='password'
                    control={control}
                    rules={{
                      minLength: {
                        value: 8,
                        message: 'Mínimo 8 caracteres.',
                      },
                      maxLength: {
                        value: 16,
                        message: 'Máximo 16 caracteres.',
                      },
                      required: 'Este campo es obligatorio.',
                    }}
                    render={({ field }) => (
                      <Input
                        {...field}
                        className='login-pass'
                        placeholder='Contraseña'
                        type={showPass ? 'string' : 'password'}
                        prefix={<LockOutlined />}
                        suffix={
                          showPass ? (
                            <EyeOutlined
                              onClick={() => setShowPass(!showPass)}
                            />
                          ) : (
                            <EyeInvisibleFilled
                              onClick={() => setShowPass(!showPass)}
                            />
                          )
                        }
                      />
                    )}
                  />
                ) : (
                  ''
                )}
                <Text type='danger'>
                  {errors.email ? (
                    <p role='alert'>{`${errors.email.message}`}</p>
                  ) : errors.password ? (
                    <p role='alert'>{`${errors.password.message}`}</p>
                  ) : (
                    ''
                  )}
                </Text>
                {!recoveryPass ? (
                  <ConfigProvider
                    theme={{
                      components: {
                        Button: {
                          colorPrimary: '#CE0A2C',
                          colorPrimaryHover: '#FD2D2E',
                          colorPrimaryActive: '#CE142C',
                        },
                      },
                    }}
                  >
                    <Button
                      type='primary'
                      onClick={handleSubmit(onSubmit)}
                      shape='round'
                      size='large'
                      style={{ width: '50%' }}
                    >
                      Acceder
                    </Button>
                  </ConfigProvider>
                ) : (
                  <ConfigProvider
                    theme={{
                      components: {
                        Button: {
                          colorPrimary: '#CE0A2C',
                          colorPrimaryHover: '#FD2D2E',
                          colorPrimaryActive: '#CE142C',
                        },
                      },
                    }}
                  >
                    <Button
                      type='primary'
                      onClick={handleSubmit(handleChangePassword)}
                      shape='round'
                      size='large'
                      style={{ width: '60%', marginTop: '2rem' }}
                    >
                      Enviar
                    </Button>
                  </ConfigProvider>
                )}
              </form>
            </div>
            {recoveryPass && (
              <Link
                onClick={() => setRecoveryPass(!recoveryPass)}
                style={{
                  marginBottom: '2rem',
                  textDecoration: 'none',
                  fontSize: '1rem',
                }}
              >
                <b>¿Ya tienes cuenta con nosotros?</b> <u>Inicia Sesión</u>
              </Link>
            )}
            <Link
              to={'/registro-usuarios'}
              style={{ marginBottom: '2rem', textDecoration: 'none' }}
            >
              ¿Aún no eres usuario? <br />
              <b>
                <u>Registrate aquí</u>
              </b>
            </Link>

            {!recoveryPass && (
              <Link
                onClick={() => setRecoveryPass(!recoveryPass)}
                style={{ textDecoration: 'none' }}
              >
                ¿Olvidaste tu contraseña?
                <b style={{ marginLeft: '1rem' }}>
                  <u>Clic aquí</u>
                </b>
              </Link>
            )}
            {errorLoggedIn && (
              <Alert
                style={{
                  textAlign: 'left',
                }}
                message='Error'
                description='Su correo y/o contraseña estan incorrectos.'
                type='error'
                showIcon
                closable
                onClick={() => setErrorLoggedIn(false)}
              />
            )}
          </div>
        </Col>
      </Row>
      <Toaster position='bottom-center' richColors />
    </ConfigProvider>
  );
};
