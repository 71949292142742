import { onAuthStateChanged, confirmPasswordReset } from 'firebase/auth';
import React, { useContext, useEffect, useState } from 'react';
import { auth } from '../../firebase/firebase';
import { getDocs, collection, getFirestore } from '@firebase/firestore';
import { useNavigate } from 'react-router-dom';

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const navigate = useNavigate();

  const [currentUser, setCurrentUser] = useState(null);
  const [userLoggedIn, setUserLoggedIn] = useState(false);
  const [loading, setLoading] = useState(true);
  const [usersList, setUsersList] = useState(undefined);
  const [userData, setUserData] = useState({});

  const db = getFirestore();

  useEffect(() => {
    const unsuscribe = onAuthStateChanged(auth, initializeUser);
    return unsuscribe;
  }, []);

  async function initializeUser(user) {
    if (user) {
      // console.log('userData auth> ', userData)
      // console.log('user auth> ', user)
      fetchUserDocs();

      setCurrentUser({ ...user });
      setUserLoggedIn(true);
    } else {
      setCurrentUser(null);
      setUserLoggedIn(false);
      setUserData({});
    }
    setLoading(false);
  }

  const fetchUserDocs = async () => {
    const querySnapshot = await getDocs(collection(db, 'users'));
    const tempArr = [];
    querySnapshot.forEach((doc) => {
      tempArr.push({ ...doc.data(), id: doc.id });
    });
    setUsersList(tempArr);
  };

  useEffect(() => {
    if (currentUser !== null && usersList !== undefined) {
      const selectedUser = usersList.filter(
        (item) => item.email === currentUser.email
      );
      localStorage.setItem('user', JSON.stringify(selectedUser[0]));
      if (selectedUser[0].role === 'admin') {
        navigate('/admin');
      } else {
        navigate('/'); // change redirection to root
      }
      setUserData(selectedUser[0]);
    }
  }, [usersList]);

  const resetPassword = (oobCode, newPassword) => {
    return confirmPasswordReset(auth, oobCode, newPassword);
  };

  const value = {
    currentUser,
    userLoggedIn,
    loading,
    usersList,
    userData,
    setUserData,
    setUserLoggedIn,
    resetPassword,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
